import { useMediaQuery } from '@kaliber/use-media-query'
import { ButtonIcon } from '/features/buildingBlocks/Button'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { LinkUnderlinedOnInteraction } from '/features/buildingBlocks/Link'
import { LanguageSwitch } from '/features/buildingBlocks/LanguageSwitch'
import { LogoFooter } from '/features/buildingBlocks/Logo'
import { extractLinkInfo } from '/machinery/extractLinkInfo'

import media from '/cssGlobal/media.css'
import styles from './Footer.css'

import iconInstagram from '/images/icons/footer/instagram.raw.svg'
import iconVimeo from '/images/icons/footer/vimeo.raw.svg'
import iconLinkedIn from '/images/icons/footer/linkedin.raw.svg'
import iconFacebook from '/images/icons/footer/facebook.raw.svg'

const socials = [
  { label: 'Instagram', icon: iconInstagram, href: 'https://www.instagram.com/digitalpowernl/' },
  { label: 'Vimeo', icon: iconVimeo, href: 'https://vimeo.com/digitalpowernl' },
  { label: 'LinkedIn', icon: iconLinkedIn, href: 'https://www.linkedin.com/company/digitalpowerbv' },
  { label: 'Facebook', icon: iconFacebook, href: 'https://www.facebook.com/digitalpowerBV/' },
]

export function Footer({ navItems, legalItems, linksToTranslations, layoutClassName = undefined }) {
  const isMd = useMediaQuery(`(min-width: ${media.breakpointMd})`)

  return (
    <footer className={cx(styles.component, layoutClassName)} data-x='footer'>
      <ContainerMd>
        <div className={styles.layout}>
          {navItems?.filter(Boolean).slice(0, 3).map((x, i) => (
            <Nav
              label={x.label}
              items={x.items}
              layoutClassName={styles[`nav${i}`]}
              key={i}
            />
          ))}
          {isMd === false && (
            <>
              <Social layoutClassName={styles.social} />
              <LegalAndLanguage layoutClassName={styles.legalAndLanguage} {...{ legalItems, linksToTranslations }} />
            </>
          )}
          {isMd !== false && (
            <>
              <SocialAndLanguage {...{ linksToTranslations }} layoutClassName={styles.socialAndLanguage} />
              <Legal items={legalItems} layoutClassName={styles.legal} />
            </>
          )}
          <LogoFooter layoutClassName={styles.logo} />
        </div>
      </ContainerMd>
    </footer>
  )
}

function LegalAndLanguage({ legalItems, linksToTranslations, layoutClassName }) {
  return (
    <div className={cx(styles.componentLegalAndLanguage, layoutClassName)}>
      <Legal items={legalItems} layoutClassName={styles.legal} />
      <LanguageSwitch {...{ linksToTranslations }} layoutClassName={styles.languageLayout} />
    </div>
  )
}

function SocialAndLanguage({ linksToTranslations, layoutClassName }) {
  return (
    <div className={cx(styles.componentSocialAndLanguage, layoutClassName)}>
      <Social layoutClassName={styles.social} />
      <LanguageSwitch {...{ linksToTranslations }} layoutClassName={styles.languageLayout} />
    </div>
  )
}

function Legal({ items, layoutClassName }) {
  return (
    <div className={cx(styles.componentLegal, layoutClassName)}>
      {items?.filter(Boolean).map((x, idx) => {
        const linkInfo = extractLinkInfo(x)
        return (
          <LinkUnderlinedOnInteraction
            key={idx}
            href={linkInfo.href}
            dataX={`link-in-footer-to-${linkInfo.label?.toLowerCase()?.replace(' ', '')}`}
            target={x._type === 'externalLink' ? '_blank' : '_self'}
          >
            {x.label}
          </LinkUnderlinedOnInteraction>
        )
      })}
    </div>
  )
}

function Nav({ label, items, layoutClassName }) {
  return (
    <nav className={cx(styles.componentNav, layoutClassName)}>
      <span className={styles.title}>{label}</span>
      {items?.filter(Boolean).map(x => {
        const linkInfo = extractLinkInfo(x)
        return (
          <LinkUnderlinedOnInteraction
            key={x._key}
            href={linkInfo.href}
            dataX={`link-in-footer-to-${linkInfo.label?.toLowerCase()?.replace(' ', '')}`}
            target={x._type === 'externalLink' ? '_blank' : '_self'}
          >
            {x.label}
          </LinkUnderlinedOnInteraction>
        )
      })}
    </nav>
  )
}

function Social({ layoutClassName }) {
  const isMd = useMediaQuery(`(min-width: ${media.breakpointLg})`)

  return (
    <div className={cx(styles.componentSocial, layoutClassName)}>
      {isMd === false && <span className={styles.title}>Social</span>}
      <nav className={styles.socialNav}>
        {socials?.filter(Boolean).map((x, i) => (
          <ButtonIcon
            key={i}
            href={x.href}
            icon={x.icon}
            label={x.label}
            dataX={`link-in-footer-to-${x.label?.toLowerCase()?.replace(' ', '')}`}
          />
        ))}
      </nav>
    </div>
  )
}
